import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { State, Action, StateContext, Store, Selector } from '@ngxs/store';
import { produce } from 'immer';
import { firstValueFrom, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ICatalogue, ICatalogueFilter } from 'src/modules/catalogue/models/catalogue.model';
import { CatalogueService } from 'src/modules/catalogue/services/catalogue.service';
import { SubscriptionStatus } from 'src/modules/microsoft/models/microsoft-subscriptions.model';
import { MicrosoftSubscriptionsService } from 'src/modules/microsoft/services/microsoft-subscriptions/microsoft-subscriptions.service';
import { BillingCycleNumber, CommitmentNumber } from 'src/modules/microsoft/shared/pipes/showCycleAndTermPipe';
import { IMicrosoftOverview } from '../models/overview.model';
import { GetMicrosoftOverview } from './overview.actions';

export class OverviewStateModel {
  public microsoftOverview: IMicrosoftOverview[];

  public isMicrosoftOverviewLoading: boolean;
}

const defaults = {
  microsoftOverview: [],
  isMicrosoftOverviewLoading: true,
};

@State<OverviewStateModel>({
  name: 'overview',
  defaults,
})
@Injectable()
export class OverviewState {
  @Selector()
  static getMicrosoftOverview(state: OverviewStateModel) {
    return state.microsoftOverview;
  }

  @Selector()
  static isMicrosoftOverviewLoading(state: OverviewStateModel) {
    return state.isMicrosoftOverviewLoading;
  }

  constructor(private store: Store, private microsoftSubscriptionsService: MicrosoftSubscriptionsService, private catalogueService: CatalogueService) {
  }

  @Action(GetMicrosoftOverview)
  GetMicrosoftOverview(ctx: StateContext<OverviewStateModel>, { customer }: GetMicrosoftOverview) {
    this.microsoftSubscriptionsService.getMicrosoftSubscriptions(customer.id).subscribe({
      next: async (data) => {
        let subscriptionsOverview: IMicrosoftOverview[] = [];

        //get only the active subscriptions
        const microsoftSubscriptions = data.filter(sub => sub.status == SubscriptionStatus.Active);

        let filterSettings: ICatalogueFilter = { pageIndex: 1, pageSize: 50, customerId: customer.id, partnerId: customer.partnerId };

        for (const sub of microsoftSubscriptions) {
          //get the right sku format
          const split = sub.sku.split(':');
          const productSku = split[0] + ':' + split[1];
          const sku = split[1] ? productSku : sub.sku;

          //prepare filter to get catalogue product by ski and date provisioned
          filterSettings = {
            ...filterSettings,
            filter: { SKUs: [sku], priceValidOn: sub.creationDate.toString() },
          };

          //get the products catalgoue synchronously
          /*
          The sku number below is for hidden Azure plan subscription as there a menu on the left for it. 
          */
          if (sku !== 'DZH318Z0BPS6:0001'){
            const products$ = this.catalogueService.getCatalogueProducts(filterSettings);
            const products = await firstValueFrom(products$);

            //find the right catalogue product for this subscription
            const catalogue: ICatalogue[] = products.results.filter(prod => ((prod.product.sku == sku) && (prod.product.billingTerm == BillingCycleNumber[sub.billingCycle]) && (prod.product.recursionTerm == CommitmentNumber[sub.termDuration])));

            //prepare the overview subscription
            const subscriptionOverview: IMicrosoftOverview = {
              subscription: sub,
              catalogue: catalogue[0],
            };

            subscriptionsOverview.push(subscriptionOverview);
          }
        }

        const state = produce(ctx.getState(), draft => {
          draft.microsoftOverview = subscriptionsOverview;
          draft.isMicrosoftOverviewLoading = false;
        });

        ctx.setState(state);
      },
    }), catchError((error: HttpErrorResponse) => {
      return throwError(() => new HttpErrorResponse(error));
    });
  }


}
